<template>
  <div class='page post'>
    <div class='banner'>
      <img src='https://cdn.s777.club/web/about-banner.png'/>
      <h1>Haidilao - Fish Table Games Online</h1>
    </div>
    <div class='content'>
      
      <p>Haidilao - an <a href="https://s777.club/">online fish table</a> product with fun, humorous, oriental-style content. Players when placing bets will certainly feel interesting and satisfied from the sound, color, graphics and many other factors. Follow us below to know more about Haidilao!</p>

      <img src='https://cdn.s777.club/web/blog/post6-Haidilao.jpg' style='margin-bottom:20px;' class='img-fluid'/>

      <h2>The Levels Of Haidilao</h2>

      <ul>
        <li>Fun Room: Bets from 0.1 to 10, symbols are colorful fish in purple pots.</li>
        <li>Rich Room: Bets from 1 to 100, the image of crabs and shrimps in the yellow jar is the symbol of this level.</li>
        <li>Vip Room: A panda with an Asian costume appears if the player chooses this room, the bet is also higher from 10 to 100.</li>
      </ul>

      <h2>The Features Of Haidilao</h2>

      <ul>
        <li>Red Envelope: During the game, players get the chance to trigger additional rewards by capturing it or hitting fishes with fixed odds. Enjoy the red envelope lottery and win a grand prize of 1000x fortune from.</li>
        <li>Lucky Slot: When players capture the lucky Slot, the special slot game will be triggered. Players can get the reward amount for each fish on the payline. If there are 3 same fish, players will get 4 times the bonus for the fish and may win up to 2000x. </li>
        <li>Sichuan Opera Doll: It can change faces every once in a while. Each face has different odds. Players work together to win a lucky bonus, get the chance to win 100x to 500x. </li>
        <li>Special Weapon - Fortune Bazooka: Players will get 10x first and additional 20 to 100 free bullets randomly. Players use it to shoot fortune bazooka again( up to 999 free bullets). </li>
        <li>Special Weapon- Mega Drill: Players will get 10x first and a free shot of Mega Drill. When fire will have the opportunity to capture the fishes along the trail path, until it explodes.</li>
      </ul>

      <h2>Paytable</h2>

      <table class='table table-bordered'>
        <tr><th>Symbols</th><th>Multiplier</th></tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable1.jpeg' class='img-fluid rounded'/></td>
          <td>x2</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable2.jpeg' class='img-fluid rounded'/></td>
          <td>x3</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable3.jpeg' class='img-fluid rounded'/></td>
          <td>x4</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable4.jpeg' class='img-fluid rounded'/></td>
          <td>x5</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable5.jpeg' class='img-fluid rounded'/></td>
          <td>x6</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable6.jpeg' class='img-fluid rounded'/></td>
          <td>x7</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable7.jpeg' class='img-fluid rounded'/></td>
          <td>x8</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable8.jpeg' class='img-fluid rounded'/></td>
          <td>x9</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable9.jpeg' class='img-fluid rounded'/></td>
          <td>x10</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable10.jpeg' class='img-fluid rounded'/></td>
          <td>x12</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable11.jpeg' class='img-fluid rounded'/></td>
          <td>x15</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable12.jpeg' class='img-fluid rounded'/></td>
          <td>x18</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable13.jpeg' class='img-fluid rounded'/></td>
          <td>x20</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable14.jpeg' class='img-fluid rounded'/></td>
          <td>x25</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable15.jpeg' class='img-fluid rounded'/></td>
          <td>x39</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable16.jpeg' class='img-fluid rounded'/></td>
          <td>x40</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable17.jpeg' class='img-fluid rounded'/></td>
          <td>x50</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable18.jpeg' class='img-fluid rounded'/></td>
          <td>x80</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable19.jpeg' class='img-fluid rounded'/></td>
          <td>x100</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable20.jpeg' class='img-fluid rounded'/></td>
          <td>x10</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable21.jpeg' class='img-fluid rounded'/></td>
          <td>x10</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable22.jpeg' class='img-fluid rounded'/></td>
          <td>x50-150</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable23.jpeg' class='img-fluid rounded'/></td>
          <td>x100</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable24.jpeg' class='img-fluid rounded'/></td>
          <td>x200</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable25.jpeg' class='img-fluid rounded'/></td>
          <td>x300</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable26.jpeg' class='img-fluid rounded'/></td>
          <td>x400</td>
        </tr>
        <tr>
          <td><img src='https://cdn.s777.club/web/blog/post6-paytable27.jpeg' class='img-fluid rounded'/></td>
          <td>x500</td>
        </tr>
      </table>

      <h2>Conclusion</h2>

      <p>In the midst of countless online fish table games that have just been released, Haidilao still holds the most popular position in S777 club. Proving that this game possesses many outstanding advantages, it is a product that players expect and love to experience. In addition, the website also organizes more promotions with attractive rewards. Hopefully this combination will make players satisfied with the service and products at S777 club!</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Post6'
}
</script>
